import React, { useState } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';

const { default: NavbarTop } = require('components/navbar/top/NavbarTop');
const { default: AppContext } = require('contexts/Context');
const { useContext } = require('react');
const { useLocation, Outlet, Navigate } = require('react-router-dom');

const PublicLayout = () => {
  const id = location.pathname.split('/')[2].split('/')[0]; //get place id from path url

  const { state } = useLocation();

  const { hash, pathname } = useLocation();

  const { firestore } = useAuth();

  const [loadedPlace, setLoadedPlace] = useState(false);

  const [deviceGroup, setDeviceGroup] = useState({
    name: state?.group?.name || '',
    description: state?.group?.description || '',
    id: state?.group?.id || id,
    public: state?.group?.public || false,
    devices: []
  });

  const {
    config: { isFluid }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);

    const getPlace = async () => {
      const place = await firestore.getPlace(deviceGroup.id);
      setDeviceGroup(place);
      setLoadedPlace(true);
    };
    getPlace();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loadedPlace && !deviceGroup.public) {
    return <Navigate to="errors/404" />;
  }

  return (
    loadedPlace && (
      <div className={isFluid ? 'container-fluid' : 'container'}>
        <div className={classNames('content')}>
          <NavbarTop isPublic={true} />
          {/*------ Main Routes ------*/}
          <Outlet />
        </div>
      </div>
    )
  );
};

export default PublicLayout;
