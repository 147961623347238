import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';

const LineNotify = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { functions, user } = useAuth();
  const [statusData, setStatusData] = useState(undefined);
  const [isConnected, setIsConnected] = useState(undefined);

  const checkLineStatus = () => {
    setIsLoading(true);
    functions.checkLineStatus().then(res => {
      // console.log(res);
      const status = res.data?.status;
      setIsConnected(status === 200);
      setStatusData(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    checkLineStatus();
  }, []);

  const handleConnect = () => {
    const params = new URLSearchParams();
    params.append('response_type', 'code');
    params.append('client_id', process.env.REACT_APP_LINE_NOTIFY_CLIENT_ID);
    params.append(
      'redirect_uri',
      process.env.REACT_APP_LINE_NOTIFY_REDIRECT_URL
    );
    params.append('scope', 'notify');
    params.append('state', user.id);
    const URL = `https://notify-bot.line.me/oauth/authorize?${params.toString()}`;
    window.location.replace(URL);
  };

  const handleDisconnect = () => {
    setIsLoading(true);
    functions.revokeLine().then(() => {
      // console.log(res);
      // console.log(res.data);
      checkLineStatus();
    });
  };

  const handleTest = () => {
    setIsLoading(true);
    const testMsg = '🎉Notification Test!🎉\nFrom ' + user.displayName;
    functions.notifyLine({ message: testMsg }).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="LINE Notify" />
      <Card.Body className="bg-light">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center bg-light w-100 h-100 opacity-75">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : isConnected ? (
          <div className="text-center">
            <div className="text-success">
              Connected{' '}
              <FontAwesomeIcon className="text-success" icon={faCheckCircle} />{' '}
            </div>
            <h3>{statusData.target}</h3>
            <Button
              variant="outline-primary"
              className="m-1"
              onClick={handleTest}
            >
              Send a test message
            </Button>
            <Button
              variant="outline-danger"
              className="m-1"
              onClick={handleDisconnect}
            >
              Disconnect
            </Button>
          </div>
        ) : (
          <div className="text-center">
            <Button className="" onClick={handleConnect}>
              Connect to LINE Notify
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default LineNotify;
