import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
// import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  // const { isAuthenticated } = useAuth();
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>{t('login')}</h5>
        <p className="fs--1 text-600 mb-0">
          {t('or')}{' '}
          <Link to="/authentication/register">{t('createAccount')}</Link>
        </p>
      </Flex>
      <LoginForm />
    </>
  );
};

export default Login;
