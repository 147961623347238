import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Table, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { Timestamp } from 'firebase/firestore';

const TextFieldRow = ({ name, value, isEdit, onChange, htmlSize }) => {
  return (
    <tr className="hover-actions-trigger">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <Form.Control
            className="w-auto ms-auto text-end"
            size="sm"
            type="text"
            step="0.5"
            htmlSize={htmlSize || 2}
            defaultValue={value === undefined ? '' : value}
            onBlur={onChange}
          />
        ) : (
          value
        )}
      </td>
    </tr>
  );
};
TextFieldRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  htmlSize: PropTypes.number
};

const DropDownRow = ({ name, value, options, isEdit, onChange }) => {
  // console.log('DropDownRowDropDownRowDropDownRow', defaultValue, options);
  return (
    <tr className="hover-actions-trigger">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <Form.Select
            size="sm"
            className="w-auto ms-auto text-center"
            value={value}
            style={{}}
            onChange={onChange}
          >
            {options.map(o => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </Form.Select>
        ) : (
          options.filter(o => o.id === value)[0]?.name
        )}
      </td>
    </tr>
  );
};
DropDownRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func
};

const DatePickerRow = ({ name, value, isEdit, onChange, htmlSize }) => {
  let date = '';
  if (value !== undefined) {
    date = new Timestamp(value.seconds, value.nanoseconds).toDate();
  } else {
    date = new Date();
  }

  return (
    <tr className="">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <ReactDatePicker
            selected={date}
            onChange={onChange}
            customInput={
              <Form.Control
                className="w-auto ms-auto text-end"
                size="sm"
                type="text"
                step="0.5"
                htmlSize={htmlSize || 2}
              />
            }
          />
        ) : (
          date.toLocaleDateString()
        )}
      </td>
    </tr>
  );
};
DatePickerRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  htmlSize: PropTypes.number
};

const getDeviceFromId = (devicesOfPlace, deviceId) => {
  const devices = devicesOfPlace.filter(d => d.id === deviceId);
  if (devices.length > 0) {
    return devices[0];
  }
  return undefined;
};
const VPDTriggerSettingTable = ({
  deviceId,
  tempDataIdx,
  humDataIdx,
  value,
  isEdit,
  devicesOfPlace,
  telemetryTypes,
  onChange
}) => {
  const { i18n, t } = useTranslation();
  const [humDataList, setHumDataList] = useState(null);
  const [tempDataList, setTempDataList] = useState(null);

  const getTelemetryTypeIdFromString = dataTypeString => {
    const matchedTypes = telemetryTypes.filter(t => t.type === dataTypeString);
    if (matchedTypes.length > 0) return parseInt(matchedTypes[0].id);
    return undefined;
  };

  const isDeviceHasRequiredData = device => {
    if (!device) return false;
    const tempDataTypeId = getTelemetryTypeIdFromString('temp');
    const humDataTypeId = getTelemetryTypeIdFromString('hum');
    const matchedTempDataMatched = device.data_list.filter(
      l => l.data_type_id === tempDataTypeId
    );
    const matchedHumDataMatched = device.data_list.filter(
      l => l.data_type_id === humDataTypeId
    );
    return (
      matchedTempDataMatched.length > 0 && matchedHumDataMatched.length > 0
    );
  };

  const getDataListAndDataIdx = (deviceId, defaultDataIdx, datatTypeId) => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    if (device) {
      // console.log(device[0].data_list[0].data_idx);
      const dataList = device.data_list.filter(
        l => l.data_type_id === datatTypeId
      );
      let resDataIdx = defaultDataIdx;
      const dataMatched = dataList.filter(l => l.data_idx === resDataIdx);
      // console.log(dataList, resDataIdx, dataMatched);
      if (dataMatched.length > 0) resDataIdx = dataMatched[0].data_idx;
      else if (dataList.length > 0) resDataIdx = dataList[0].data_idx;
      return { dataList, dataIdx: resDataIdx };
    }
    return {
      dataList: undefined,
      humDataIdx: undefined,
      tempDataIdx: undefined
    };
  };

  useEffect(() => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    // console.log("vpd-device",device)
    if (!isDeviceHasRequiredData(device)) {
      const devices = devicesOfPlace?.filter(d => isDeviceHasRequiredData(d));
      onChange({ device_id: devices[0]?.id });
      return;
    }
    const tempDataTypeId = getTelemetryTypeIdFromString('temp');
    const humDataTypeId = getTelemetryTypeIdFromString('hum');
    const { dataList: newTempDataList, dataIdx: newTempDataIdx } =
      getDataListAndDataIdx(deviceId, tempDataIdx, tempDataTypeId);
    const { dataList: newHumDataList, dataIdx: newHumDataIdx } =
      getDataListAndDataIdx(deviceId, humDataIdx, humDataTypeId);
    setTempDataList(newTempDataList);
    setHumDataList(newHumDataList);
    // console.log(tempDataIdx, newTempDataIdx, humDataIdx, newHumDataIdx);

    let changeObj = {};
    if (tempDataIdx !== newTempDataIdx || humDataIdx !== newHumDataIdx) {
      changeObj = {
        temp_data_idx: newTempDataIdx,
        hum_data_idx: newHumDataIdx
      };
    }
    if (value === undefined) {
      changeObj = { ...changeObj, value: 0 };
    }
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }
  }, [deviceId]);

  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        name={t('device')}
        value={deviceId}
        options={devicesOfPlace
          ?.filter(d => isDeviceHasRequiredData(d))
          .map(d => ({ id: d.id, name: d.name }))}
        onChange={e => onChange({ device_id: e.target.value })}
      />
      {tempDataList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('temperatureData')}
          value={tempDataIdx}
          options={tempDataList.map(d => ({
            id: d.data_idx,
            name:
              telemetryTypes?.[d?.data_type_id]?.localized_name?.[
                i18n.language
              ] + (d?.tag ? ' - ' + d?.tag : '')
          }))}
          onChange={e => onChange({ temp_data_idx: parseInt(e.target.value) })}
        />
      )}

      {humDataList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('humidityData')}
          value={humDataIdx}
          options={humDataList.map(d => ({
            id: d.data_idx,
            name:
              telemetryTypes?.[d?.data_type_id]?.localized_name?.[
                i18n.language
              ] + (d?.tag ? ' - ' + d?.tag : '')
          }))}
          onChange={e => onChange({ hum_data_idx: parseInt(e.target.value) })}
        />
      )}

      <TextFieldRow
        isEdit={isEdit}
        name={t('threshold')}
        value={value}
        onChange={e => onChange({ value: parseFloat(e.target.value) })}
      />
    </>
  );
};
VPDTriggerSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  deviceId: PropTypes.string,
  tempDataIdx: PropTypes.number,
  humDataIdx: PropTypes.number,
  value: PropTypes.any,
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

const ValueTriggerSettingTable = ({
  deviceId,
  dataIdx,
  dataTypeId,
  value,
  isEdit,
  devicesOfPlace,
  telemetryTypes,
  onChange
}) => {
  const { i18n, t } = useTranslation();
  const [dataList, setDataList] = useState(null);

  const getDataListAndDataIdx = (
    deviceId,
    defaultDataIdx,
    defaultDataTypeId
  ) => {
    const device = devicesOfPlace.filter(d => d.id === deviceId);
    if (device.length > 0) {
      // console.log(device[0].data_list[0].data_idx);
      const dataList = device[0].data_list;
      let resDataIdx = defaultDataIdx;
      let resDataTypeId = defaultDataTypeId;
      const dataMatched = dataList.filter(l => l.data_idx === resDataIdx);
      // console.log(dataList, resDataIdx, dataMatched);
      if (dataMatched.length > 0) {
        resDataIdx = dataMatched[0].data_idx;
        resDataTypeId = dataMatched[0].data_type_id;
      } else if (device[0].data_list.length > 0) {
        resDataIdx = device[0].data_list[0].data_idx;
        resDataTypeId = device[0].data_list[0].data_type_id;
      }
      return { dataList, dataIdx: resDataIdx, dataTypeId: resDataTypeId };
    }
    return { dataList: undefined, dataIdx: undefined, dataTypeId: undefined };
  };

  useEffect(() => {
    if (deviceId === undefined) {
      onChange({ device_id: devicesOfPlace?.[0]?.id });
      return;
    }
    const {
      dataList: newDataList,
      dataIdx: newDataIdx,
      dataTypeId: newDataTypeId
    } = getDataListAndDataIdx(deviceId, dataIdx, dataTypeId);
    setDataList(newDataList);

    let changeObj = {};
    // console.log(dataIdx ,newDataIdx, dataTypeId ,newDataTypeId)
    if (dataIdx !== newDataIdx || dataTypeId !== newDataTypeId) {
      changeObj = { data_idx: newDataIdx, data_type_id: newDataTypeId };
    }
    if (value === undefined) {
      changeObj = { ...changeObj, value: 0 };
    }
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }
  }, [deviceId]);

  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        name={t('device')}
        value={deviceId}
        options={devicesOfPlace?.map(d => ({ id: d.id, name: d.name }))}
        onChange={e => onChange({ device_id: e.target.value })}
      />
      {dataList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('data')}
          value={dataIdx}
          options={dataList.map((d, index) => ({
            id: index,
            value: d,
            name:
              telemetryTypes?.[d?.data_type_id]?.localized_name?.[
                i18n.language
              ] + (d?.tag ? ' - ' + d?.tag : '')
          }))}
          onChange={e => {
            onChange({
              data_idx: dataList?.[e.target.value]?.data_idx,
              data_type_id: dataList?.[e.target.value]?.data_type_id
            });
          }}
        />
      )}
      <TextFieldRow
        isEdit={isEdit}
        name={t('threshold')}
        value={value}
        onChange={e => onChange({ value: parseFloat(e.target.value) })}
      />
    </>
  );
};
ValueTriggerSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  deviceId: PropTypes.string,
  dataIdx: PropTypes.number,
  dataTypeId: PropTypes.number,
  value: PropTypes.any,
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

const PortSettingTable = ({
  type,
  deviceId,
  portIdx,
  isEdit,
  params,
  devicesOfPlace,
  onChange
}) => {
  const { t } = useTranslation();
  const [portList, setPortList] = useState(undefined);

  const isDeviceHasPort = device => {
    if (!device) return false;
    if (!device.n_ports) return false;
    return device.n_ports > 0;
  };

  const getPortListAndPortIdx = (deviceId, defaultPortIdx) => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    if (device) {
      // console.log(device.n_ports);
      const nPorts = device.n_ports;
      if (nPorts > 0) {
        var list = [];
        for (var i = 0; i < nPorts; i++) {
          list.push(i);
        }
        return {
          portList: list,
          portIdx: defaultPortIdx < nPorts ? defaultPortIdx : 0
        };
      }
    }
    return { portList: undefined, portIdx: undefined };
  };

  useEffect(() => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    // console.log('device', device);
    if (!isDeviceHasPort(device)) {
      const devices = devicesOfPlace?.filter(d => isDeviceHasPort(d));
      onChange({ device_id: devices[0]?.id });
      return;
    }
    if (deviceId === undefined) {
      onChange({ device_id: devicesOfPlace?.[0]?.id });
      return;
    }
    const { portList: newPortList, portIdx: newPortIdx } =
      getPortListAndPortIdx(deviceId, portIdx);
    setPortList(newPortList);

    let changeObj = {};
    if (portIdx !== newPortIdx) changeObj = { port: newPortIdx };
    if (params === undefined) {
      changeObj = { ...changeObj, params: [0, 0] };
    }
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }
  }, [deviceId]);

  // console.log('portList', portList,portIdx);
  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        name={t('device')}
        value={deviceId}
        options={devicesOfPlace
          ?.filter(d => isDeviceHasPort(d))
          .map(d => ({ id: d.id, name: d.name }))}
        onChange={e => onChange({ device_id: e.target.value })}
      />
      {portList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('port')}
          value={portIdx}
          options={portList?.map(portIdx => ({
            id: portIdx,
            name: `${portIdx + 1}`
          }))}
          onChange={e => onChange({ port: parseInt(e.target.value) })}
        />
      )}
      {type === 'port-turn-toggle-on' && (
        <TextFieldRow
          isEdit={isEdit}
          name={`${t('on')} (${t('sec')})`}
          value={params?.[0]}
          onChange={e =>
            onChange({ params: [parseInt(e.target.value), params?.[1]] })
          }
        />
      )}
      {type === 'port-turn-toggle-on' && (
        <TextFieldRow
          isEdit={isEdit}
          name={`${t('off')} (${t('sec')})`}
          value={params?.[1]}
          onChange={e =>
            onChange({ params: [params?.[0], parseInt(e.target.value)] })
          }
        />
      )}
    </>
  );
};
PortSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  type: PropTypes.string,
  params: PropTypes.array,
  deviceId: PropTypes.string,
  portIdx: PropTypes.number
};

const PortTriggerSettingTable = ({
  deviceId,
  portIdx,
  portState,
  isEdit,
  devicesOfPlace,
  onChange
}) => {
  const { t } = useTranslation();
  const [portList, setPortList] = useState(undefined);

  const isDeviceHasPort = device => {
    if (!device) return false;
    if (!device.n_ports) return false;
    return device.n_ports > 0;
  };

  const getPortListAndPortIdx = (deviceId, defaultPortIdx) => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    if (device) {
      // console.log(device.n_ports);
      const nPorts = device.n_ports;
      if (nPorts > 0) {
        var list = [];
        for (var i = 0; i < nPorts; i++) {
          list.push(i);
        }
        return {
          portList: list,
          portIdx: defaultPortIdx < nPorts ? defaultPortIdx : 0
        };
      }
    }
    return { portList: undefined, portIdx: undefined };
  };

  useEffect(() => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    // console.log('device', device);
    if (!isDeviceHasPort(device)) {
      const devices = devicesOfPlace?.filter(d => isDeviceHasPort(d));
      onChange({ device_id: devices[0]?.id });
      return;
    }
    if (deviceId === undefined) {
      onChange({ device_id: devicesOfPlace?.[0]?.id });
      return;
    }
    const { portList: newPortList, portIdx: newPortIdx } =
      getPortListAndPortIdx(deviceId, portIdx);
    setPortList(newPortList);

    let changeObj = {};
    if (portIdx !== newPortIdx) changeObj = { port: newPortIdx };
    if (!['on', 'off'].includes(portState))
      changeObj = { ...changeObj, state: 'off' };
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }
  }, [deviceId]);

  // console.log('portList', portList,portIdx);
  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        name={t('device')}
        value={deviceId}
        options={devicesOfPlace
          ?.filter(d => isDeviceHasPort(d))
          .map(d => ({ id: d.id, name: d.name }))}
        onChange={e => onChange({ device_id: e.target.value })}
      />
      {portList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('port')}
          value={portIdx}
          options={portList?.map(portIdx => ({
            id: portIdx,
            name: `${portIdx + 1}`
          }))}
          onChange={e => onChange({ port: parseInt(e.target.value) })}
        />
      )}
      {portList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('state')}
          value={portState}
          options={[
            {
              id: 'off',
              name: `Off`
            },
            {
              id: 'on',
              name: `On`
            }
          ]}
          onChange={e => onChange({ state: e.target.value })}
        />
      )}
    </>
  );
};
PortTriggerSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  deviceId: PropTypes.string,
  portIdx: PropTypes.number,
  portState: PropTypes.string
};

const LineNotifyTable = ({ text, isEdit, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextFieldRow
        htmlSize={25}
        isEdit={isEdit}
        name={`${t('message')}`}
        value={text}
        onChange={e => onChange({ text: e.target.value })}
      />
    </>
  );
};

LineNotifyTable.propTypes = {
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string
};

const GDDTriggerSettingTable = ({
  deviceId,
  tempDataIdx,
  value,
  startDate,
  isEdit,
  devicesOfPlace,
  telemetryTypes,
  onChange
}) => {
  const { i18n, t } = useTranslation();
  const [tempDataList, setTempDataList] = useState(null);

  const getTelemetryTypeIdFromString = dataTypeString => {
    const matchedTypes = telemetryTypes.filter(t => t.type === dataTypeString);
    if (matchedTypes.length > 0) return parseInt(matchedTypes[0].id);
    return undefined;
  };

  const isDeviceHasRequiredData = device => {
    if (!device) return false;
    const tempDataTypeId = getTelemetryTypeIdFromString('temp');
    const matchedTempDataMatched = device.data_list.filter(
      l => l.data_type_id === tempDataTypeId
    );
    return matchedTempDataMatched.length > 0;
  };

  const getDataListAndDataIdx = (deviceId, defaultDataIdx, datatTypeId) => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    if (device) {
      // console.log(device[0].data_list[0].data_idx);
      const dataList = device.data_list.filter(
        l => l.data_type_id === datatTypeId
      );
      let resDataIdx = defaultDataIdx;
      const dataMatched = dataList.filter(l => l.data_idx === resDataIdx);
      // console.log(dataList, resDataIdx, dataMatched);
      if (dataMatched.length > 0) resDataIdx = dataMatched[0].data_idx;
      else if (dataList.length > 0) resDataIdx = dataList[0].data_idx;
      return { dataList, dataIdx: resDataIdx };
    }
    return {
      dataList: undefined,
      tempDataIdx: undefined
    };
  };

  useEffect(() => {
    const device = getDeviceFromId(devicesOfPlace, deviceId);
    // console.log("vpd-device",device)
    if (!isDeviceHasRequiredData(device)) {
      const devices = devicesOfPlace?.filter(d => isDeviceHasRequiredData(d));

      onChange({ device_id: devices[0]?.id });
      return;
    }
    const tempDataTypeId = getTelemetryTypeIdFromString('temp');
    const { dataList: newTempDataList, dataIdx: newTempDataIdx } =
      getDataListAndDataIdx(deviceId, tempDataIdx, tempDataTypeId);
    setTempDataList(newTempDataList);
    // console.log(newTempDataList);

    let changeObj = {};
    if (tempDataIdx !== newTempDataIdx) {
      changeObj = {
        temp_data_idx: newTempDataIdx
      };
    }
    if (value === undefined) {
      changeObj = { ...changeObj, value: 0 };
    }
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }

    if (startDate === undefined) {
      const dateWithZeroTime = new Date();
      dateWithZeroTime.setHours(0, 0, 0, 0);
      changeObj = {
        ...changeObj,
        start_date: Timestamp.fromDate(dateWithZeroTime)
      };
      onChange(changeObj);
    } else {
      startDate = new Timestamp(startDate.seconds, startDate.nanoseconds);
    }
    console.log(changeObj);
  }, [deviceId]);

  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        name={t('device')}
        value={deviceId}
        options={devicesOfPlace
          ?.filter(d => isDeviceHasRequiredData(d))
          .map(d => ({ id: d.id, name: d.name }))}
        onChange={e => onChange({ device_id: e.target.value })}
      />
      {tempDataList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('temperatureData')}
          value={tempDataIdx}
          options={tempDataList.map(d => ({
            id: d.data_idx,
            name:
              telemetryTypes?.[d?.data_type_id]?.localized_name?.[
                i18n.language
              ] + (d?.tag ? ' - ' + d?.tag : '')
          }))}
          onChange={e => onChange({ temp_data_idx: parseInt(e.target.value) })}
        />
      )}
      <DatePickerRow
        htmlSize={13}
        isEdit={isEdit}
        name={t('startDate')}
        value={startDate}
        onChange={date => onChange({ start_date: Timestamp.fromDate(date) })}
      />
      <TextFieldRow
        isEdit={isEdit}
        name={t('threshold')}
        value={value}
        onChange={e => onChange({ value: parseFloat(e.target.value) })}
      />
    </>
  );
};
GDDTriggerSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  deviceId: PropTypes.string,
  tempDataIdx: PropTypes.number,
  startDate: PropTypes.number,
  value: PropTypes.any,
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

const settingTypes = {
  time: 'TimeTriggerSettingTable',
  'vpd-threshold-above': 'VPDTriggerSettingTable',
  'vpd-threshold-below': 'VPDTriggerSettingTable',
  'tele-threshold-above': 'ValueTriggerSettingTable',
  'tele-threshold-below': 'ValueTriggerSettingTable',
  'port-turn-on': 'PortSettingTable',
  'port-turn-off': 'PortSettingTable',
  'port-turn-toggle-on': 'PortSettingTable',
  'line-notify': 'LineNotifyTable',
  'gdd-threshold': 'GDDTriggerSettingTable',
  port: 'PortTriggerSettingTable'
};

const SettingView = ({
  title,
  setting,
  isEdit,
  devicesOfPlace,
  telemetryTypes,
  supportedSettingTypes,
  onChange,
  onRemove
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (setting.type === undefined) {
      //defualt value
      onChange({ type: supportedSettingTypes[0] });
    }
    console.log(setting);
  }, [setting]);

  return (
    <>
      <FalconCardHeader
        title={title}
        className="bg-soft-secondary text-center p-2 rounded-0"
        titleTag="h6"
        endEl={
          isEdit ? (
            <IconButton
              variant="link"
              className="text-danger fs--1 py-0 m-0"
              size="sm"
              icon="trash"
              iconAlign="left"
              onClick={onRemove}
            >
              Remove
            </IconButton>
          ) : undefined
        }
      />
      <Table hover responsive borderless size="sm">
        <tbody>
          <DropDownRow
            isEdit={isEdit}
            name={t('type')}
            value={setting.type}
            options={supportedSettingTypes.map(type => ({
              id: type,
              name: t(type)
            }))}
            onChange={e => onChange({ type: e.target.value })}
          />
          {settingTypes[setting.type] === 'VPDTriggerSettingTable' && (
            <VPDTriggerSettingTable
              deviceId={setting.device_id}
              tempDataIdx={setting.temp_data_idx}
              humDataIdx={setting.hum_data_idx}
              value={setting.value}
              devicesOfPlace={devicesOfPlace}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
          {settingTypes[setting.type] === 'ValueTriggerSettingTable' && (
            <ValueTriggerSettingTable
              deviceId={setting.device_id}
              dataIdx={setting.data_idx}
              dataTypeId={setting.data_type_id}
              value={setting.value}
              devicesOfPlace={devicesOfPlace}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
          {settingTypes[setting.type] === 'PortSettingTable' && (
            <PortSettingTable
              type={setting.type}
              deviceId={setting.device_id}
              portIdx={setting.port}
              params={setting.params}
              devicesOfPlace={devicesOfPlace}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
          {settingTypes[setting.type] === 'PortTriggerSettingTable' && (
            <PortTriggerSettingTable
              deviceId={setting.device_id}
              portIdx={setting.port}
              portState={setting.state}
              devicesOfPlace={devicesOfPlace}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
          {settingTypes[setting.type] === 'LineNotifyTable' && (
            <LineNotifyTable
              text={setting.text}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
          {settingTypes[setting.type] === 'GDDTriggerSettingTable' && (
            <GDDTriggerSettingTable
              deviceId={setting.device_id}
              tempDataIdx={setting.temp_data_idx}
              humDataIdx={setting.hum_data_idx}
              value={setting.value}
              startDate={setting.start_date}
              devicesOfPlace={devicesOfPlace}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
        </tbody>
      </Table>
    </>
  );
};
SettingView.propTypes = {
  trigger: PropTypes.object,
  isEdit: PropTypes.bool,
  setting: PropTypes.object,
  supportedSettingTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export const SettingsList = ({
  title,
  subTitle,
  isLoading,
  hasTypeField,
  defaultSettings,
  defaultType,
  devicesOfPlace,
  telemetryTypes,
  supportedSettingTypes,
  onSave
}) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [settingsList, setSettingsList] = useState(defaultSettings);
  const [settingsType, setSettingsType] = useState(defaultType);
  // const navigate = useNavigate();

  return (
    <>
      {settingsList && (
        <>
          <Card className={`overflow-hidden`} style={{ position: 'unset' }}>
            <FalconCardHeader
              title={title}
              className="bg-soft-light"
              titleTag="h5"
              endEl={
                hasTypeField ? (
                  isEdit ? (
                    <Form.Select
                      size="sm"
                      className="me-2"
                      value={settingsType}
                      onChange={e => {
                        setSettingsType(e.target.value);
                      }}
                    >
                      <option value="any">{t('triggersTypeAny')}</option>
                      <option value="all">{t('triggersTypeAll')}</option>
                    </Form.Select>
                  ) : settingsType == 'all' ? (
                    t('triggersTypeAll')
                  ) : (
                    t('triggersTypeAny')
                  )
                ) : undefined
              }
            />
            {settingsList.map((setting, i) => {
              // console.log(i,defaultTriggers,triggers,trigger)
              return (
                <SettingView
                  title={`${subTitle} ${i + 1}`}
                  setting={setting}
                  devicesOfPlace={devicesOfPlace}
                  telemetryTypes={telemetryTypes}
                  supportedSettingTypes={supportedSettingTypes}
                  isEdit={isEdit}
                  key={i}
                  onChange={e => {
                    const newSettingsList = settingsList.slice();
                    newSettingsList[i] = { ...newSettingsList[i], ...e };
                    // console.log('newSettingsList', newSettingsList[i]);
                    setSettingsList(newSettingsList);
                  }}
                  onRemove={() => {
                    const newSettingsList = settingsList
                      .slice()
                      .filter((t, idx) => idx != i);
                    setSettingsList(newSettingsList);
                  }}
                />
              );
            })}
            <Card.Footer className="row text-left bg-soft-light border-top">
              {isEdit ? (
                <>
                  <div className="col">
                    <IconButton
                      variant="outline-primary"
                      size="sm"
                      icon="plus"
                      iconAlign="left"
                      onClick={() => {
                        const newSettingsList = settingsList.slice();
                        newSettingsList.push({});
                        setSettingsList(newSettingsList);
                      }}
                    >
                      {t('add')}
                    </IconButton>
                  </div>
                  <div className="text-right col-auto">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => {
                        // console.log(defaultTriggers);
                        setSettingsList(defaultSettings);
                        setSettingsType(defaultType);
                        setIsEdit(false);
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="ms-2"
                      onClick={() => {
                        setIsEdit(false);
                        // console.log(triggersType, triggers);
                        onSave(settingsList, settingsType);
                      }}
                    >
                      {t('save')}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col"></div>
                  <div className="text-right col-auto">
                    <IconButton
                      variant="primary"
                      size="sm"
                      className="ms-2"
                      icon="edit"
                      iconAlign="left"
                      onClick={() => setIsEdit(true)}
                    >
                      {t('edit')}
                    </IconButton>
                  </div>
                </>
              )}
            </Card.Footer>
            {isLoading && (
              <div className="position-absolute d-flex justify-content-center align-items-center bg-light w-100 h-100 opacity-75">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </Card>
        </>
      )}
    </>
  );
};

SettingsList.propTypes = {
  defaultSettings: PropTypes.arrayOf(PropTypes.object),
  defaultType: PropTypes.string,
  onSave: PropTypes.func,
  supportedSettingTypes: PropTypes.arrayOf(PropTypes.string),
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  devicesOfPlace: PropTypes.arrayOf(PropTypes.object),
  hasTypeField: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string
};
