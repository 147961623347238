import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
// import { useNavigate,  useParams } from 'react-router-dom';
import { Col, Row, Card, Button } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { SettingsList } from './TriggersActions';

const Automation = () => {
  const location = useLocation();
  const [historyState] = useState(location.state);
  const { t } = useTranslation();
  const { firestore } = useAuth();
  const [devicesOfPlace, setDevicesOfPlace] = useState(null);
  const [automation, setAutomation] = useState(null);
  const { gid, id } = useParams();
  const [isTriggersLoading, setIsTriggersLoading] = useState(false);
  const [isActionsLoading, setIsActionsLoading] = useState(false);

  const [telemetryTypes, setTelemetryTypes] = useState(null);
  // const navigate = useNavigate();

  useEffect(() => {
    if (firestore) {
      firestore.getDevicesOfPlace(gid).then(_devices => {
        // console.log(gid, _devices);
        const filteredDevices = _devices.filter(
          d => !d.id.startsWith('ct-view-')
        );
        setDevicesOfPlace(filteredDevices);
      });
      firestore.getAutomation(gid, id).then(_automation => {
        // console.log(gid, id, _automation);
        setAutomation(_automation);
      });
    }
  }, [firestore, gid, id]);

  useEffect(() => {
    if (firestore) {
      Promise.all([firestore.getTelemetryTypes()]).then(([telemetryTypes]) => {
        // console.log(telemetryTypes);
        setTelemetryTypes(telemetryTypes);
      });
    }
  }, [firestore]);

  return (
    <>
      {automation && devicesOfPlace && telemetryTypes && (
        <>
          <Row className="g-3 mb-3">
            <Col lg={12} className="gx-0">
              <Card className={`overflow-hidden`}>
                <Background image={corner1} className="p-card bg-card" />
                <Card.Body className="position-relative">
                  <h3 className="">{automation.name}</h3>
                  <p className="fs--1 mb-0">{t('automation')}</p>
                  {historyState && (
                    <Button
                      as={Link}
                      variant="link"
                      className="fs--1 text-primary mt-1 pb-0 ps-0"
                      to={`/group/${gid}`}
                      state={{ group: historyState.group }}
                    >
                      <FontAwesomeIcon icon="chevron-left" className="fs--1" />{' '}
                      {t('backTo')} {historyState.group.name}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} className="gx-0">
              <Row className="g-3 mb-3">
                <Col lg={6} className="position-relative">
                  <SettingsList
                    title={t('triggers')}
                    subTitle={t('trigger')}
                    isLoading={isTriggersLoading}
                    hasTypeField={true}
                    defaultSettings={automation.triggers}
                    defaultType={automation.triggers_type}
                    devicesOfPlace={devicesOfPlace}
                    telemetryTypes={telemetryTypes}
                    supportedSettingTypes={[
                      // 'time',
                      'tele-threshold-above',
                      'tele-threshold-below',
                      'vpd-threshold-above',
                      'vpd-threshold-below',
                      'gdd-threshold',
                      'port'
                    ]}
                    onSave={(triggers, triggersType) => {
                      setIsTriggersLoading(true);
                      firestore
                        .setTriggersOfAutomation(
                          gid,
                          id,
                          triggers,
                          triggersType
                        )
                        .then(() => {
                          firestore.getAutomation(gid, id).then(_automation => {
                            // console.log(gid, id, _automation);
                            setAutomation(_automation);
                            setIsTriggersLoading(false);
                          });
                        });
                    }}
                  />
                </Col>
                <Col lg={6} className="">
                  <SettingsList
                    title={t('actions')}
                    subTitle={t('action')}
                    isLoading={isActionsLoading}
                    hasTypeField={false}
                    defaultSettings={automation.actions}
                    devicesOfPlace={devicesOfPlace}
                    telemetryTypes={telemetryTypes}
                    supportedSettingTypes={[
                      'port-turn-on',
                      'port-turn-off',
                      'port-turn-toggle-on',
                      'line-notify'
                    ]}
                    onSave={actions => {
                      setIsActionsLoading(true);
                      firestore
                        .setActionsOfAutomation(gid, id, actions)
                        .then(() => {
                          firestore.getAutomation(gid, id).then(_automation => {
                            setAutomation(_automation);
                            setIsActionsLoading(false);
                          });
                        });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Automation;
