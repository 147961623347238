import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const colorTones = [
  '#785cb3',
  '#0080ff',
  '#008542',
  '#ffbf00',
  '#ff9933',
  '#d20603',
  '#F4511E'
];

const getOptions = (names, allData, colors, hasAreaColor) => ({
  color: getColor('100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: function (params) {
      let out = '';
      const date = new Date(params[0].value[0]);
      var chartdate = moment(date).format('ll HH:mm:ss');
      params.forEach((param, idx) => {
        out +=
          '<li style="list-style:none">' +
          param.marker +
          names[idx] +
          '&nbsp;&nbsp;' +
          param.value[1] +
          '</li>';
      });
      return chartdate + out;
    },
    transitionDuration: 0
  },
  xAxis: {
    type: 'time',
    // boundaryGap: true,
    splitNumber: 0,
    splitLine: { show: false },
    axisLine: {
      show: false,
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: { show: false }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      show: false,
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    // boundaryGap: true,
    splitNumber: 1,
    axisLabel: {
      show: true,
      color: getColor('400'),
      margin: 5
    },
    axisTick: { show: false },
    axisLine: { show: false },
    min: Math.floor(
      Math.min(...allData.map(data => Math.min(...data.map(d => d[1]))))
    ),
    max: Math.ceil(
      Math.max(...allData.map(data => Math.max(...data.map(d => d[1]))))
    )
  },
  series: allData.map((data, idx) => {
    return {
      type: 'line',
      data,
      lineStyle: { color: colors[idx] },
      itemStyle: {
        borderWidth: 0,
        color: colors[idx]
      },
      symbolSize: 0,
      smooth: false,
      areaStyle: !hasAreaColor
        ? undefined
        : {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(colors[idx], 0.2)
                },
                {
                  offset: 1,
                  color: rgbaColor(colors[idx], 0)
                }
              ]
            }
          }
    };
  }),
  grid: { right: 0, left: 0, bottom: 10, top: 10, containLabel: true }
});

const getOptionsBar = (names, allData, colors) => ({
  xAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },
  yAxis: [
    {
      inverse: true,
      type: 'category',
      data: names,
      position: 'left',
      // boundaryGap: true,
      axisLabel: {
        show: true,
        color: getColor('gray-500')
      },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      }
    },
    {
      inverse: true,
      type: 'category',
      data: allData.map(data => data[data.length - 1][1]?.toLocaleString()),
      position: 'right',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#000',
        inside: false,
        show: true,
        align: 'left'
      }
    }
  ],
  grid: { right: 2, left: 2, bottom: 0, top: 0, containLabel: true },
  series: [
    {
      data: allData.map((data, idx) => ({
        value: data[data.length - 1][1],
        itemStyle: { color: colors[idx], borderRadius: [0, 3, 3, 0] }
      })),
      barWidth: '90%',
      type: 'bar'
    }
  ]
});

function countData(allData) {
  return Object.entries(allData)
    .map(([, v]) => v.length)
    .reduce((a, b) => a + b, 0);
}

const TelemetryRealtimeAll = ({
  deviceId,
  dataTypeId,
  dataDetails,
  telemetryType,
  chartType
}) => {
  const { database } = useAuth();
  const [allData, setAllData] = useState({});
  const [dataPointCount, setDataPointCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(true);

  const prefixName = telemetryType?.localized_name?.[i18n.language];

  useEffect(() => {
    let unsubscribes = [];
    database
      .getRealtimeDataAllValue(deviceId, dataTypeId)
      .then(allRealtimeValueData => {
        let lastKeys = {};
        if (allRealtimeValueData) {
          for (const [dataIdx, realtimeValueData] of Object.entries(
            allRealtimeValueData
          )) {
            lastKeys[dataIdx] = 0;
            if (realtimeValueData) {
              const keys = Object.keys(realtimeValueData);
              lastKeys[dataIdx] = keys[keys.length - 1];
              allData[dataIdx] = [];
              allData[dataIdx].push(
                ...keys.map(key => [
                  new Date(parseInt(key)).toISOString(),
                  realtimeValueData[key]
                ])
              );
            }
          }
        } else {
          dataDetails.forEach(dataDetail => {
            lastKeys[dataDetail['data_idx']] = 0;
            allData[dataDetail['data_idx']] = [];
          });
        }
        setAllData(allData);
        setDataPointCount(countData(allData));
        setLoading(false);
        unsubscribes = dataDetails.map(dataDetail =>
          database.onRealtimeDataChildAdded(
            deviceId,
            dataDetail['data_type_id'],
            dataDetail['data_idx'],
            lastKeys[dataDetail['data_idx']],
            (time, value) => {
              allData[dataDetail['data_idx']].push([
                new Date(parseInt(time)).toISOString(),
                value
              ]);
              setAllData(allData);
              setDataPointCount(countData(allData));
            }
          )
        );
      });
    return () => {
      unsubscribes.forEach(unsubscribe => {
        unsubscribe();
      });
    };
  }, []);

  const filteredSubfixNames = dataDetails
    .filter(d => allData[d['data_idx']] && allData[d['data_idx']].length > 0)
    .map(d => (d?.tag ? d?.tag : ''));
  const filteredDataList = dataDetails
    .filter(d => allData[d['data_idx']] && allData[d['data_idx']].length > 0)
    .map(d => allData[d['data_idx']]);
  const filteredColorTones =
    filteredDataList.length === 1
      ? [getColor('primary')]
      : dataDetails
          .map((d, i) => [d, i])
          .filter(
            ([d]) => allData[d['data_idx']] && allData[d['data_idx']].length > 0
          )
          .map(([, i]) => colorTones[i]);

  if (
    isLoading ||
    (dataPointCount > 0 && filteredDataList && filteredDataList.length > 0)
  ) {
    return (
      <div className="mb-2" style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <h6>{prefixName}</h6>
          {filteredDataList && filteredDataList.length === 1 && (
            <h6>{filteredDataList[0][filteredDataList[0].length - 1][1]}</h6>
          )}
        </div>
        {chartType === 'LINES' && (
          <ReactEChartsCore
            loadingOption={{
              text: t('loading'),
              color: getColor('primary')
            }}
            showLoading={isLoading}
            echarts={echarts}
            option={getOptions(
              filteredSubfixNames,
              filteredDataList,
              filteredColorTones,
              true
            )}
            style={{ height: '3rem' }}
          />
        )}
        {chartType === 'BARS' && (
          <>
            <ReactEChartsCore
              loadingOption={{
                text: t('loading'),
                color: getColor('primary')
              }}
              showLoading={isLoading}
              echarts={echarts}
              option={getOptionsBar(
                filteredSubfixNames,
                filteredDataList,
                filteredColorTones
              )}
              style={{ height: '6rem' }}
            />
          </>
        )}
      </div>
    );
  }

  return <></>;
};

TelemetryRealtimeAll.propTypes = {
  className: PropTypes.string,
  deviceId: PropTypes.string.isRequired,
  dataTypeId: PropTypes.number.isRequired,
  dataDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  telemetryType: PropTypes.object.isRequired,
  chartType: PropTypes.string.isRequired
};

const DeviceListItem = ({
  className,
  name = '',
  description = '',
  id = '',
  device = ''
}) => {
  const [deviceState, setDeviceState] = useState(null);

  const { t } = useTranslation();

  const { firestore, database } = useAuth();

  const [dataList, setDataList] = useState(null);

  const [, setSensorsList] = useState(null);

  const [telemetryTypes, setTelemetryTypes] = useState(null);

  const groupPath = location.pathname.match('public-group')
    ? 'public-group'
    : 'group';

  useEffect(() => {
    if (database) {
      let unsubscribe = null;
      unsubscribe = database.onDeviceStatesValue(device.id, newDeviceState => {
        setDeviceState(newDeviceState);
      });
      return () => {
        if (unsubscribe != null) {
          unsubscribe();
        }
      };
    }
  }, [database, device.id]);

  useEffect(() => {
    if (device) {
      Promise.all([firestore.getTelemetryTypes()]).then(([telemetryTypes]) => {
        setTelemetryTypes(telemetryTypes);
        setDataList(device['data_list']);
        setSensorsList(device['sensors_list']);
      });
    }
  }, [device]);

  return (
    <Card className={`${className}`}>
      <Card.Body className="position-relative  pb-5">
        <h5 className="text-primary">
          {device.name}
          {deviceState && (
            <FontAwesomeIcon
              icon="circle"
              style={{ marginBottom: 2 }}
              className={`ms-2 fs--2 text-${
                deviceState.online ? 'primary' : 'danger'
              }`}
            />
          )}
        </h5>
        <p className="fs--1 mb-3">{device.model}</p>

        <div style={{ width: '100%' }}>
          {dataList &&
            dataList
              .map(d => d.data_type_id)
              .filter(onlyUnique)
              .filter(dataTypeId => ![0].includes(dataTypeId)) //filter out wifi (0 is WiFi)
              .map(dataTypeId => (
                <TelemetryRealtimeAll
                  key={dataTypeId}
                  deviceId={device.id}
                  dataTypeId={dataTypeId}
                  dataDetails={dataList.filter(
                    d => d.data_type_id === dataTypeId
                  )}
                  telemetryType={telemetryTypes[dataTypeId]}
                  chartType={dataTypeId !== 7 ? 'LINES' : 'BARS'} // BARS for SPECTRUM (7 is SPECTRUM)
                />
              ))}
        </div>
        <div className="position-absolute text-end bottom-0 end-0">
          <Button
            as={Link}
            variant="link"
            className="fs--1 fw-medium text-primary m-2 ps-0 "
            to={`/${groupPath}/${id}/device/${device.id}`}
            state={{
              group: {
                name: name,
                description: description,
                id: id
              }
            }}
          >
            {t('view')}
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

DeviceListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  device: PropTypes.object.isRequired
};

export default DeviceListItem;
