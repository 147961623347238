import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Profile from 'components/pages/user/profile/Profile';
import Device from 'components/pages/device/Device';
import Settings from 'components/pages/user/settings/Settings';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import AppContext from 'contexts/Context';
import DeviceGroup from 'components/pages/group/DeviceGroup';
import DeviceGroupList from 'components/pages/group-list/DeviceGroupList';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import Automation from 'components/pages/automation/Automation';
import LineNotifyCallback from 'components/pages/user/settings/LineNotifyCallback';
import PublicLayout from './PublicLayout';
import DeviceAutomation from 'components/pages/device/deviceAutomation/DeviceAutomation';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- simple ---------------------------  */}
        <Route
          element={
            <GuestGuard>
              <AuthSimpleLayout />
            </GuestGuard>
          }
        >
          <Route path="authentication/login" element={<SimpleLogin />} />
          <Route
            path="authentication/register"
            element={<SimpleRegistration />}
          />
          <Route path="authentication/logout" element={<SimpleLogout />} />
          <Route
            path="authentication/forgot-password"
            element={<SimpleForgetPassword />}
          />
          <Route
            path="authentication/reset-password"
            element={<SimplePasswordReset />}
          />
          <Route
            path="authentication/confirm-mail"
            element={<SimpleConfirmMail />}
          />
          <Route
            path="authentication/lock-screen"
            element={<SimpleLockScreen />}
          />
        </Route>
        {/* //--- MainLayout Starts  */}

        <Route
          element={
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          }
        >
          {/*Dashboard*/}
          <Route path="/" element={<DeviceGroupList />} />
          {/*Pages*/}
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/settings" element={<Settings />} />
          <Route
            path="user/settings/line/callback"
            element={<LineNotifyCallback />}
          />
          <Route path="group/:id" element={<DeviceGroup />} />
          <Route path="group/:gid/device/:id" element={<Device />} />
          <Route path="group/:gid/automation/:id" element={<Automation />} />
          <Route
            path="group/:gid/device/:deviceId/deviceAutomation/:id"
            element={<DeviceAutomation />}
          />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />

        {/* //-------- Public Section ------- */}
        <Route element={<PublicLayout />}>
          <Route path="public-group/:id" element={<DeviceGroup />} />
          <Route path="public-group/:gid/device/:id" element={<Device />} />
          <Route
            path="public-group/:gid/automation/:id"
            element={<Automation />}
          />
          <Route
            path="public-group/:gid/device/:deviceId/deviceAutomation/:id"
            element={<DeviceAutomation />}
          />
        </Route>
      </Routes>
      {/* <SettingsToggle /> */}
      {/* <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
