import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFan, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import QuantityController from 'components/common/QuantityController';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

library.add(faFan, faPowerOff);

const PortListItem = ({ className, portId, portState, deviceId, auto }) => {
  const { t } = useTranslation();
  const { functions, database } = useAuth();
  const [isAuto, setIsAuto] = useState(auto === undefined || auto);
  const [isToggle, setIsToggle] = useState((portState?.s || 0) >= 2);
  const [isOn, setIsOn] = useState(portState?.s % 2 === 1);
  const [toggleOnSec, setToggleOnSec] = useState(portState?.p?.[0] || 30);
  const [toggleOffSec, setToggleOffSec] = useState(portState?.p?.[1] || 30);
  const [triggerCounter, setTriggerCounter] = useState(0);
  const [softTriggerCounter, setSoftTriggerCounter] = useState(0);
  const [lastSoftSendUpdateTime, setLastSoftSendUpdateTime] = useState(0);
  const [lastSendUpdateTime, setLastSendUpdateTime] = useState(0);
  const SOFT_UPDATE_DELAY_TIME_MS = 1500;

  const sendUpdate = () => {
    setLastSendUpdateTime(Date.now());
    setTriggerCounter(triggerCounter + 1);
  };

  const softSendUpdate = () => {
    setLastSoftSendUpdateTime(Date.now());
    setTimeout(() => {
      setSoftTriggerCounter(softTriggerCounter + 1);
    }, SOFT_UPDATE_DELAY_TIME_MS);
  };

  useEffect(() => {
    if (softTriggerCounter > 0) {
      // console.log(Date.now() - lastRequestToSendUpdateTime, softTriggerCounter);
      if (Date.now() - lastSoftSendUpdateTime >= SOFT_UPDATE_DELAY_TIME_MS) {
        sendUpdate();
      } else {
        softSendUpdate();
      }
    }
  }, [softTriggerCounter]);

  useEffect(() => {
    if (triggerCounter > 0) {
      // console.log('setSwitch', triggerCounter);
      // console.log(isToggle, isOn, toggleOnSec, toggleOffSec);
      functions.setSwitchManual({
        devId: deviceId,
        port: portId,
        state: (isOn ? 1 : 0) + (isToggle ? 2 : 0),
        params: [toggleOnSec, toggleOffSec]
      });
    }
  }, [triggerCounter]);

  useEffect(() => {
    // console.log(portId,Date.now() - lastSendUpdateTime)
    if (
      portState != undefined &&
      Date.now() - lastSoftSendUpdateTime >= SOFT_UPDATE_DELAY_TIME_MS && //not update the device state to UI if an user is changing numbers
      Date.now() - lastSendUpdateTime >= 2000 // not update the device state to UI if we just sent an command in the last two seconds.
    ) {
      setIsOn(portState?.s % 2 === 1);
      setIsToggle((portState?.s || 0) >= 2);
      setToggleOnSec(portState?.p?.[0] || 30);
      setToggleOffSec(portState?.p?.[1] || 30);
    }
  }, [portState]);

  useEffect(() => {
    // console.log(portId,Date.now() - lastSendUpdateTime)
    if (auto != undefined) {
      setIsAuto(auto);
    }
  }, [auto]);

  return (
    <Card className={`overflow-hidden  h-100 ${className}`}>
      {/* <Background image={corner1} className="p-card bg-card" /> */}
      <Card.Body className="position-relative">
        {!isAuto && (
          <div className="float-end" style={{ width: '49px', height: '49px' }}>
            <Button
              size="lg"
              className={classNames('rounded-circle w-100 h-100 p-0', {
                'bg-200 border-0': !isOn
              })}
              onClick={() => {
                setIsOn(!isOn);
                sendUpdate();
              }}
            >
              <FontAwesomeIcon
                icon="power-off"
                className={classNames('mt-1 fs-3', { 'text-700': !isOn })}
              />
            </Button>
          </div>
        )}
        {isAuto && (
          <div
            // style={isOn ? {textShadow: "0px 0px 20px #7abf60"} : undefined}
            className={classNames('float-end fs-4 fw-semi-bold pe-2 ', {
              'text-primary': isOn,
              'text-500': !isOn
            })}
          >
            {isOn ? t('on') : t('off')}
          </div>
        )}
        <Form.Check
          type="switch"
          className=""
          id={`enable-auto-button-${portId}`}
        >
          <Form.Check.Input
            type="checkbox"
            style={{ width: '3rem', height: '1.5rem' }}
            className="me-2"
            checked={isAuto}
            onChange={event => {
              database.setAutoFlag(deviceId, portId, event.target.checked);
              // setIsAuto(event.target.checked);
            }}
          />
          <Form.Check.Label
            className={classNames('mb-1 mt-1', {
              'text-primary fw-semi-bold': isAuto,
              'text-500': !isAuto
            })}
          >
            {t('auto')}
          </Form.Check.Label>
        </Form.Check>
        <h5 className="text-primary">
          {/* Fan 1 <FontAwesomeIcon icon="fan" /> */}
          {t('port')} {portId + 1}
        </h5>
        <p className="fs--1 mb-1">
          {isAuto ? t('autoMode') : t('manualMode')} - {t('port')} {portId + 1}
        </p>

        <div
          style={{
            minHeight: '69px',
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'row wrap'
          }}
        >
          {!isAuto && (
            <>
              <Form.Check
                inline
                aria-label="toggle"
                name="group1"
                className="align-middle me-2 mt-2"
                type="checkbox"
                id={`toggle-button-${portId}`}
              >
                <Form.Check.Input
                  checked={isToggle}
                  onChange={event => {
                    setIsToggle(event.target.checked);
                    sendUpdate();
                  }}
                ></Form.Check.Input>
                <Form.Check.Label
                  className={classNames('fs-0 mb-0', {
                    'text-primary ': isToggle
                  })}
                >
                  {t('toggle')}
                </Form.Check.Label>
              </Form.Check>
              {isToggle && (
                <div className="d-inline-block align-middle">
                  <div className="d-inline-block me-2">
                    <p className="center text-center mb-0 fs--1">{t('on')}</p>
                    <QuantityController
                      className=""
                      inputClassName="text-primary"
                      btnClassName="px-2"
                      quantity={toggleOnSec}
                      handleChange={e => {
                        if (!isNaN(parseInt(e.target.value))) {
                          setToggleOnSec(parseInt(e.target.value));
                          softSendUpdate();
                        }
                      }}
                      handleIncrease={() => {
                        setToggleOnSec(toggleOnSec + 1);
                        softSendUpdate();
                      }}
                      handleDecrease={() => {
                        setToggleOnSec(toggleOnSec - 1);
                        softSendUpdate();
                      }}
                    />
                    <p className="center text-center mb-0 fs--1">{t('sec')}</p>
                  </div>
                  <div className="d-inline-block me-2">
                    <p className="center text-center mb-0 fs--1">{t('off')}</p>
                    <QuantityController
                      className=""
                      inputClassName="text-primary"
                      btnClassName="px-2"
                      quantity={toggleOffSec}
                      handleChange={e => {
                        if (!isNaN(parseInt(e.target.value))) {
                          setToggleOffSec(parseInt(e.target.value));
                          softSendUpdate();
                        }
                      }}
                      handleIncrease={() => {
                        setToggleOffSec(toggleOffSec + 1);
                        softSendUpdate();
                      }}
                      handleDecrease={() => {
                        setToggleOffSec(toggleOffSec - 1);
                        softSendUpdate();
                      }}
                    />
                    <p className="center text-center mb-0 fs--1">{t('sec')}</p>
                  </div>
                </div>
              )}
            </>
          )}
          {isAuto &&
            isOn &&
            (isToggle ? (
              <label className="mt-2 pt-1 fs-0 text-primary">
                {t('toggle')}: {t('on')} {toggleOnSec} {t('sec')} / {t('off')}{' '}
                {toggleOffSec} {t('sec')}
              </label>
            ) : (
              <label className="mt-2 pt-1 fs-0"> {t('noToggle')}</label>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

PortListItem.propTypes = {
  className: PropTypes.string,
  portId: PropTypes.number,
  portState: PropTypes.object,
  deviceId: PropTypes.string,
  auto: PropTypes.bool
};

export default PortListItem;
