import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuth from 'hooks/useAuth';

const LanguageSettings = () => {
  const { user, firestore } = useAuth();

  const [language, setLanguage] = useState(user.languageCode);
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setLanguage(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    firestore.setUserLanguage(language).then(() => {
      window.location.reload();
    });
  };

  return (
    <Card>
      <FalconCardHeader title="Language Setting" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="Display Name">
              <Form.Label>Language</Form.Label>
              <Form.Select value={language} onChange={handleChange}>
                <option value="th">ไทย</option>
                <option value="en">English</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button variant="primary" type="submit">
              {loading ? (
                <Spinner
                  animation="border"
                  variant="white"
                  className=""
                  size="sm"
                />
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default LanguageSettings;
