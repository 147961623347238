import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
// import { useNavigate,  useParams } from 'react-router-dom';
import { Col, Row, Card, Button, Nav } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import Sensors from './sensors/Sensors';
import Ports from './ports/Ports';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import DeviceAutomations from './deviceAutomation/DeviceAutomations';

const Device = () => {
  const location = useLocation();
  const [historyState] = useState(location.state);
  const { t } = useTranslation();
  const { firestore, database } = useAuth();
  const [device, setDevice] = useState(null);
  const [deviceState, setDeviceState] = useState(null);
  const { id } = useParams();
  const [subpageList, setSubpageList] = useState(null);
  const [subpage, setSubpage] = useState(null);
  // const navigate = useNavigate();

  const groupPath = location.pathname.match('public-group')
    ? 'public-group'
    : 'group';

  useEffect(() => {
    if (firestore) {
      firestore.getDevice(id).then(data => {
        setDevice(data);
      });
    }
  }, [firestore, id]);

  useEffect(() => {
    // let lastUpdateTimestamp = Date.now();
    if (database) {
      let unsubscribe = null;
      unsubscribe = database.onDeviceStatesValue(id, newDeviceState => {
        setDeviceState(newDeviceState);
      });
      return () => {
        if (unsubscribe != null) {
          unsubscribe();
        }
      };
    }
  }, [database, id]);

  useEffect(() => {
    // let lastUpdateTimestamp = Date.now();
    if (device) {
      const newSubpageList = ['sensors'];
      if (device.n_ports && device.n_ports > 0) {
        newSubpageList.push('ports');
      }
      newSubpageList.push('deviceAutomation');
      setSubpageList(newSubpageList);
      setSubpage(newSubpageList[0]);
    }
  }, [device]);

  const subpageComponents = {
    sensors: <Sensors device={device} />,
    ports: <Ports device={device} deviceState={deviceState} />,
    deviceAutomation: <DeviceAutomations device={device} />
  };

  return (
    <>
      {device && (
        <>
          <Row className="g-3 mb-3">
            <Col lg={12} className="gx-0">
              <Card className={`overflow-hidden`}>
                <Background image={corner1} className="p-card bg-card" />
                <Card.Body className="position-relative">
                  <h3 className="">
                    {device.name}
                    {deviceState && (
                      <FontAwesomeIcon
                        icon="circle"
                        className={`ms-2 fs--2  mb-1 text-${
                          deviceState.online ? 'primary' : 'danger'
                        }`}
                      />
                    )}
                  </h3>
                  <p className="fs--1 mb-0">
                    {t('device')} {device.model}
                  </p>
                  {deviceState && deviceState.firmwareVersion && (
                    <p className="fs--1 mb-0">
                      {`${t('firmware')} ${deviceState.firmwareVersion}`}
                    </p>
                  )}
                  {historyState && (
                    <Button
                      as={Link}
                      variant="link"
                      className="fs--1 text-primary mt-1 pb-0 ps-0"
                      to={`/${groupPath}/${historyState.group.id}`}
                      state={{ group: historyState.group }}
                    >
                      <FontAwesomeIcon icon="chevron-left" className="fs--1" />{' '}
                      {t('backTo')} {historyState.group.name}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
            {subpage && (
              <>
                <Col lg={12} className="gx-0">
                  <Nav
                    variant="pills"
                    defaultActiveKey={subpage}
                    onSelect={key => setSubpage(key)}
                  >
                    {subpageList.map(btnName => (
                      <>
                        {groupPath === 'public-group' &&
                        btnName === 'deviceAutomation' ? (
                          <></>
                        ) : (
                          <Nav.Item key={btnName}>
                            <Nav.Link eventKey={btnName}>{t(btnName)}</Nav.Link>
                          </Nav.Item>
                        )}
                      </>
                    ))}
                  </Nav>
                </Col>
                <Col lg={12} className="gx-0">
                  {subpageComponents[subpage]}
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default Device;
