import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate,  useParams } from 'react-router-dom';
import { Col, Row, Card, Nav, Placeholder } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import RealtimeChart from './RealtimeChart';
import AnalyticalChart from './AnalyticalChart';
import th from 'date-fns/locale/th'; // the locale you want
import en from 'date-fns/locale/en-US'; // the locale you want
import HistoricalChart from './HistoricalChart';

const placeholder = (
  <>
    <Placeholder bg="secondary" className="w-25" size="sm" />
    <br />
    <br />
    <Placeholder bg="secondary" className="w-100" />
    <Placeholder bg="secondary" className="w-100" />
    <Placeholder bg="secondary" className="w-100" />
    <Placeholder bg="secondary" className="w-75" />
  </>
);

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const DateRangeInputField = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange
}) => {
  const { i18n } = useTranslation();
  return (
    <Row className="g-3 mt-0 mb-1">
      <Col lg={2} md={3} sm={4} xs={6} className="mt-1">
        <DatePicker
          selected={startDate}
          onChange={date => onStartDateChange(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={i18n.language === 'th' ? 'd MMM yyyy' : 'MMM d, yyyy'}
          locale={i18n.language === 'th' ? th : en}
          className="form-control form-control-sm"
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={6} className="mt-1">
        <DatePicker
          selected={endDate}
          onChange={date => onEndDateChange(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat={i18n.language === 'th' ? 'd MMM yyyy' : 'MMM d, yyyy'}
          locale={i18n.language === 'th' ? th : en}
          className="form-control form-control-sm"
        />
      </Col>
    </Row>
  );
};
DateRangeInputField.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func
};

const RealtimeChartsSubPage = ({ dataList, device, telemetryTypes }) => {
  return dataList
    .map(d => d.data_type_id)
    .filter(onlyUnique)
    .map(dataTypeId => (
      <RealtimeChart
        key={dataTypeId}
        deviceId={device.id}
        dataTypeId={dataTypeId}
        dataDetails={dataList.filter(d => d.data_type_id === dataTypeId)}
        telemetryType={telemetryTypes[dataTypeId]}
      />
    ));
};

const HistoricalChartsSubPage = ({ dataList, device, telemetryTypes }) => {
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <DateRangeInputField
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      {dataList.map((dataDetail, idx) => (
        <HistoricalChart
          key={idx}
          deviceId={device.id}
          dataDetail={dataDetail}
          sensor={device?.sensors_list?.[dataDetail?.sensor_index]}
          telemetryType={telemetryTypes[dataDetail?.data_type_id]}
          startDate={startDate}
          endDate={endDate}
        />
      ))}
    </>
  );
};
HistoricalChartsSubPage.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.object,
  telemetryTypes: PropTypes.arrayOf(PropTypes.object)
};

const AnalyticalChartsSubPage = ({ dataList, device, telemetryTypes }) => {
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <DateRangeInputField
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      {/* TODO: REMOVE HARD CODING */}
      {/* Without spectrum cases*/}
      {dataList
        .filter(d => d.data_type_id !== 7)
        .map((dataDetail, idx) => (
          <AnalyticalChart
            key={idx}
            deviceId={device.id}
            dataDetail={dataDetail}
            sensor={device?.sensors_list?.[dataDetail?.sensor_index]}
            telemetryType={telemetryTypes[dataDetail?.data_type_id]}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      {/*Spectrum cases*/}
      {dataList
        .filter(d => d.data_type_id === 7)
        .map(d => d.sensor_index)
        .filter(onlyUnique)
        .map(sensorIndex => {
          const dataDetail = dataList.filter(
            d => d.sensor_index === sensorIndex && d.data_type_id === 7
          )[0];
          return dataDetail ? (
            <AnalyticalChart
              key={sensorIndex}
              deviceId={device.id}
              dataDetail={dataDetail}
              sensor={device?.sensors_list?.[sensorIndex]}
              telemetryType={telemetryTypes[dataDetail?.data_type_id]}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <></>
          );
        })}
    </>
  );
};
AnalyticalChartsSubPage.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.object,
  telemetryTypes: PropTypes.arrayOf(PropTypes.object)
};

const Sensors = ({ device }) => {
  const { firestore } = useAuth();
  const { t } = useTranslation();
  const [dataList, setDataList] = useState(null);
  const [telemetryTypes, setTelemetryTypes] = useState(null);

  const subpageList = ['realtime', 'historical', 'analytical'];
  const [subpage, setSubpage] = useState(subpageList[0]);
  const [subpageComponent, setSubpageComponent] = useState(placeholder);

  useEffect(() => {
    if (device) {
      Promise.all([firestore.getTelemetryTypes()]).then(([telemetryTypes]) => {
        setTelemetryTypes(telemetryTypes);
        setDataList(device['data_list']);
      });
    }
  }, [device]);

  useEffect(() => {
    if (subpage && dataList && telemetryTypes) {
      let subpageComponent = subpageComponent;
      switch (subpage) {
        case 'realtime':
          subpageComponent = (
            <RealtimeChartsSubPage
              dataList={dataList}
              device={device}
              telemetryTypes={telemetryTypes}
            />
          );
          break;
        case 'historical':
          subpageComponent = (
            <HistoricalChartsSubPage
              dataList={dataList}
              device={device}
              telemetryTypes={telemetryTypes}
            />
          );
          break;
        case 'analytical':
          subpageComponent = (
            <AnalyticalChartsSubPage
              dataList={dataList}
              device={device}
              telemetryTypes={telemetryTypes}
            />
          );
          break;
      }
      setSubpageComponent(subpageComponent);
    }
  }, [subpage, dataList, telemetryTypes]);

  return (
    <>
      {device && (
        <Card className={`overflow-hidden`}>
          <Card.Body className="position-relative pt-3">
            <Row className="g-3">
              <Col lg={12} className="">
                <Nav
                  // justify
                  // fill
                  className="border-bottom-0"
                  variant="tabs"
                  defaultActiveKey={subpage}
                  onSelect={key => setSubpage(key)}
                >
                  {subpageList.map(btnName => (
                    <Nav.Item key={btnName}>
                      <Nav.Link className="px-0 me-4" eventKey={btnName}>
                        {t(btnName)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col lg={12} className="">
                {subpageComponent}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

Sensors.propTypes = {
  device: PropTypes.object
};

export default Sensors;
