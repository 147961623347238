import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'contexts/Context';
import LogoNoBg from 'components/common/LogoNoBg';
import NavbarNavLink from './NavbarNavLink';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useTranslation } from 'react-i18next';

const NavbarTop = ({ isPublic = false }) => {
  const { t } = useTranslation();
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const [, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('fs-0 navbar-top sticky-kit mb-3')}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <div className="navbar-glass"></div>
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <LogoNoBg at="navbar-top" width={45} id="topLogo" />

      {(navbarPosition === 'top' || navbarPosition === 'combo') && !isPublic ? (
        <Navbar.Collapse
          in={navbarCollapsed}
          className={classNames('scrollbar pb-lg-0 navbar-collapsed-bg', {
            // "pt-3 pb-3": navbarCollapsed,
            // "pt-2 pb-2": !navbarCollapsed,
          })}
        >
          <Nav navbar>
            <NavbarNavLink route={{ to: '/', name: t('home'), active: true }} />
            <NavbarNavLink
              route={{
                to: '/user/settings',
                name: t('settings'),
                active: true
              }}
            />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
          as="ul"
        />
      )}

      {!isPublic && <TopNavRightSideNavItem />}
    </Navbar>
  );
};

NavbarTop.propTypes = {
  isPublic: PropTypes.bool.isRequired
};

export default NavbarTop;
