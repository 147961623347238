import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { AuthProvider } from 'contexts/FirebaseContext';
import './i18n';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Suspense fallback={<> Loading </>}>
        <Main>
          <App />
        </Main>
      </Suspense>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('main')
);
