import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function encodeAnalyticsParams(
  deviceId,
  dataId,
  startDate,
  endDate,
  reportSettings
) {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  const params = {
    DEVICE_ID: deviceId,
    DATA_ID: dataId,
    DP_TIMEZONE: 'Asia/Bangkok',
    START_TIMESTAMP: moment
      .utc(startDate)
      .format('YYYY-MM-DD HH:mm:ss.SSS UTC'),
    END_TIMESTAMP: moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss.SSS UTC')
  };
  if (reportSettings) Object.assign(params, reportSettings);

  const paramsString = JSON.stringify(params);

  return encodeURIComponent(paramsString);
}

const HistoricalChart = ({
  deviceId,
  dataDetail,
  sensor,
  telemetryType,
  startDate,
  endDate
}) => {
  // const { database } = useAuth();
  // const [data, setData] = useState([]);
  // const [dataLength, setDataLength] = useState(0);
  // const [isLoading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const name =
    telemetryType?.localized_name?.[i18n.language] +
    (dataDetail?.tag ? ' - ' + dataDetail?.tag : '');

  useEffect(() => {
    console.log(sensor, telemetryType);
  }, []);

  return (
    <>
      {telemetryType.raws &&
        telemetryType.raws.map((raw, aindex) => (
          <Row key={aindex} className="g-3 mb-3">
            <Col>
              <FalconCardHeader
                title={name + (raw.tag ? ` (${raw.tag})` : '')}
                titleTag="h6"
                className="px-0"
              />

              {raw ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      aspectRatio: '4 / 1',
                      overflow: 'hidden'
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        aspectRatio: '32 / 9'
                      }}
                    >
                      <iframe
                        title="Historical"
                        width="100%"
                        height="100%"
                        src={`${raw?.url}?params=${encodeAnalyticsParams(
                          deviceId,
                          dataDetail?.data_idx,
                          startDate,
                          endDate,
                          sensor?.report_settings
                        )}`}
                        frameBorder={0}
                        allowFullScreen={false}
                      ></iframe>
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center my-5">{t('noData')}</p>
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};

HistoricalChart.propTypes = {
  deviceId: PropTypes.string.isRequired,
  dataDetail: PropTypes.object.isRequired,
  sensor: PropTypes.object.isRequired,
  telemetryType: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired
};

export default HistoricalChart;
