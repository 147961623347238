import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, Form } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const DeviceAutomations = ({ device }) => {
  const { id } = useParams();

  const { state } = useLocation();

  const { firestore } = useAuth();

  const [deviceGroup, setDeviceGroup] = useState({
    name: state?.group?.name || '',
    description: state?.group?.description || '',
    id: state?.group?.id || id
  });

  const [deviceAutomations, setDeviceAutomations] = useState([]);

  const { t } = useTranslation();

  const groupPath = location.pathname.match('public-group')
    ? 'public-group'
    : 'group';

  const handleAddDeviceAutomation = () => {
    const tempName =
      'New Device Automation #' +
      parseInt(
        deviceAutomations.filter(a =>
          a.name.startsWith('New Device Automation')
        ).length + 1
      );

    const deviceAutomation = {
      name: tempName,
      triggers: [],
      actions: [],
      triggers_type: 'any'
    };

    firestore.addDeviceAutomation(device.id, deviceAutomation).then(docRef => {
      deviceAutomation.id = docRef.id;
      deviceAutomation.isEditing = false; //for edit name
      setDeviceAutomations([...deviceAutomations, deviceAutomation]);
    });
  };

  const handleDeviceAutomationNameChange = (e, deviceAutomationId) => {
    const _deviceAutomations = [...deviceAutomations];
    const index = _deviceAutomations.findIndex(
      a => a.id === deviceAutomationId
    );
    _deviceAutomations[index].newName = e.target.value;
    setDeviceAutomations(_deviceAutomations);
  };

  const handleSetDeviceAutomationName = (e, deviceAutomationId) => {
    const _deviceAutomations = [...deviceAutomations];
    const index = _deviceAutomations.findIndex(
      a => a.id === deviceAutomationId
    );
    if (_deviceAutomations[index].newName) {
      const newName = _deviceAutomations[index].newName;
      if (newName !== _deviceAutomations[index].name) {
        _deviceAutomations[index].name = newName;
        firestore.setDeviceAutomationName(
          device.id,
          deviceAutomationId,
          newName
        );
      }
      _deviceAutomations[index].isEditing = false;
      setDeviceAutomations(_deviceAutomations);
    } else {
      _deviceAutomations[index].isEditing = false;
      setDeviceAutomations(_deviceAutomations);
    }
  };

  const handleDeleteDeviceAutomation = (e, deviceAutomationId) => {
    firestore.deleteDeviceAutomation(device.id, deviceAutomationId).then(() => {
      setDeviceAutomations(
        deviceAutomations.filter(a => a.id !== deviceAutomationId)
      );
    });
  };

  useEffect(() => {
    if (firestore) {
      Promise.all([
        firestore.getPlace(deviceGroup.id),
        firestore.getDeviceAutomations(device.id)
      ]).then(([place, deviceAutomations]) => {
        const _deviceGroup = { ...deviceGroup };
        _deviceGroup.name = place.name;
        _deviceGroup.description = place.description;
        setDeviceGroup(_deviceGroup);
        setDeviceAutomations([...deviceAutomations], { isEditing: false });
      });
    }
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        {deviceAutomations &&
          deviceAutomations.map(deviceAutomation => {
            const { id, name, actions, triggers } = deviceAutomation;
            return (
              <Col key={id} lg={6}>
                <Card>
                  <Card.Body className="position-relative">
                    <Flex direction="row" alignItems="center">
                      {deviceAutomation.isEditing ? (
                        <Form.Control
                          className=" fs--1 px-0 w-auto "
                          size="sm"
                          type="text"
                          onChange={e =>
                            handleDeviceAutomationNameChange(e, id)
                          }
                          defaultValue={name === undefined ? '' : name}
                          onBlur={e => handleDeviceAutomationNameChange(e, id)}
                        />
                      ) : (
                        <Card.Text className="text-primary m-0">
                          {name}
                        </Card.Text>
                      )}
                      {!deviceAutomation.isEditing ? (
                        <FontAwesomeIcon
                          onClick={() => {
                            const _deviceAutomations = [...deviceAutomations];
                            const index = _deviceAutomations.findIndex(
                              a => a.id === id
                            );
                            _deviceAutomations[index].isEditing = true;
                            setDeviceAutomations(_deviceAutomations);
                          }}
                          icon="edit"
                          className="px-2 cursor-pointer"
                          color="text-secondary"
                        />
                      ) : (
                        <FontAwesomeIcon
                          onClick={e => handleSetDeviceAutomationName(e, id)}
                          icon={faSave}
                          className="px-2 cursor-pointer"
                          color="text-secondary"
                        />
                      )}
                    </Flex>

                    <Flex direction="column py-2">
                      <p className="fs--2 m-0 text-secondary ">
                        {t('actions')}: {actions ? actions.length : 0}
                      </p>
                      <p className="fs--2 m-0 text-secondary">
                        {t('triggers')}: {triggers ? triggers.length : 0}
                      </p>
                    </Flex>
                  </Card.Body>
                  <Card.Footer>
                    <Flex
                      direction="row"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <FontAwesomeIcon
                        onClick={e => handleDeleteDeviceAutomation(e, id)}
                        icon="trash"
                        className="cursor-pointer"
                        color="text-secondary"
                      />
                      <Button
                        as={Link}
                        variant="link"
                        className="fs--1 text-primary px-0"
                        to={`/${groupPath}/${deviceGroup.id}/device/${device.id}/deviceAutomation/${id}`}
                        state={{
                          group: {
                            name: deviceGroup.name,
                            description: deviceGroup.description,
                            id: deviceGroup.id
                          }
                        }}
                      >
                        {t('view')}
                        <FontAwesomeIcon
                          icon="chevron-right"
                          className="ms-1 fs--2"
                        />
                      </Button>
                    </Flex>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12} className="text-end">
          <IconButton
            className="w-100 w-lg-auto"
            variant="outline-primary"
            size="sm"
            icon="plus"
            iconAlign="left"
            onClick={handleAddDeviceAutomation}
          >
            {t('add')}
          </IconButton>
        </Col>
      </Row>
    </>
  );
};

export default DeviceAutomations;
