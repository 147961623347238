import React from 'react';
import PropTypes from 'prop-types';
// import { useNavigate,  useParams } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import PortListItem from './PortListItem';

const Ports = ({ device, deviceState }) => {
  return (
    <>
      {device && device.n_ports && (
        <Container fluid>
          <Row className="g-3 mb-3">
            {Array.from(Array(device.n_ports).keys()).map(index => (
              <Col md={6} key={index}>
                <PortListItem
                  portId={index}
                  deviceId={device.id}
                  portState={deviceState?.state?.ports?.[index]}
                  auto={deviceState?.auto?.ports?.[index]}
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};
Ports.propTypes = {
  device: PropTypes.object,
  deviceState: PropTypes.object
};
export default Ports;
