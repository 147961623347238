import React, { useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/authentication-corner.png';
import Background from 'components/common/Background';
import DeviceGroupListItem from './DeviceGroupListItem';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';

const defaultPlacesValue = [
  {
    description: '',
    devices: [],
    id: '',
    name: '',
    users: []
  }
];

const DeviceGroupList = () => {
  const { firestore, user } = useAuth();

  const { getPlacesByUserId } = firestore;

  const [places, setPlaces] = useState(defaultPlacesValue);

  useEffect(() => {
    const fetchPlacesByUserId = async () => {
      const places = await getPlacesByUserId(user.id);

      setPlaces(places);
    };

    fetchPlacesByUserId();
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={12} className="gx-0">
          <Card className={`overflow-hidden`}>
            <Background image={corner1} className="p-card bg-card" />
            <Card.Body className="position-relative">
              <h1 className="">Device Groups</h1>
              <p className="fs--1 mb-0">
                Group of IoT devices that you can manage and monitor
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12} className="gx-0">
          <Row className="g-3 mb-3">
            {places &&
              places.map((place, index) => {
                return (
                  <Col key={index} lg={6}>
                    <DeviceGroupListItem {...place} />
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DeviceGroupList;
