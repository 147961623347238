import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from 'hooks/useAuth';

const SocialAuthButtons = () => {
  const { loginWithGoogle, loginWithFacebook } = useAuth();
  const handleClickLoginWithGoogle = () => {
    loginWithGoogle();
  };

  const handleClickLoginWithFacebook = () => {
    loginWithFacebook();
  };
  return (
    <Form.Group className="mb-0">
      <Row>
        <Col sm={6} className="pe-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-google-plus mt-2 w-100"
            onClick={handleClickLoginWithGoogle}
          >
            <FontAwesomeIcon
              icon={['fab', 'google']}
              transform="grow-8"
              className="me-2"
            />{' '}
            google
          </Button>
        </Col>
        <Col sm={6} className="ps-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-facebook mt-2 w-100"
            onClick={handleClickLoginWithFacebook}
          >
            <FontAwesomeIcon
              icon={['fab', 'facebook-square']}
              transform="grow-8"
              className="me-2"
            />{' '}
            facebook
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default SocialAuthButtons;
