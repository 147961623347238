import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import useAuth from 'hooks/useAuth';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const colorTones = [
  '#785cb3',
  '#0080ff',
  '#008542',
  '#ffbf00',
  '#ff9933',
  '#d20603',
  '#F4511E'
];

const getOptions = (names, dataList, colors) => ({
  color: getColor('100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: function (params) {
      let out = '';
      const date = new Date(params[0].value[0]);
      var chartdate = moment(date).format('ll HH:mm:ss');
      params.forEach((param, idx) => {
        out +=
          '<li style="list-style:none">' +
          param.marker +
          names[idx] +
          '&nbsp;&nbsp;' +
          param.value[1] +
          '</li>';
      });
      return chartdate + out;
    },
    transitionDuration: 0
  },
  xAxis: {
    type: 'time',
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    splitNumber: 4,
    splitLine: { show: true },
    axisLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('400'),
      margin: 15
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: dataList.map((data, idx) => ({
    type: 'line',
    data,
    lineStyle: {
      color: colors[idx]
    },
    itemStyle: {
      borderColor: colors[idx],
      borderWidth: 0,
      color: colors[idx]
    },
    symbol: 'circle',
    symbolSize: 0,
    smooth: false,
    areaStyle:
      dataList.length > 1
        ? undefined
        : {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(colors[idx], 0.2)
                },
                {
                  offset: 1,
                  color: rgbaColor(colors[idx], 0)
                }
              ]
            }
          }
  })),
  grid: { right: 1, left: 1, bottom: 0, top: 10, containLabel: true }
});

function countData(allData) {
  return Object.entries(allData)
    .map(([, v]) => v.length)
    .reduce((a, b) => a + b, 0);
}

const RealtimeChart = ({
  deviceId,
  dataTypeId,
  dataDetails,
  telemetryType
}) => {
  const { database } = useAuth();
  const [allData, setAllData] = useState({});
  const [dataPointCount, setDataPointCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(true);

  const prefixName = telemetryType?.localized_name?.[i18n.language];

  useEffect(() => {
    let unsubscribes = [];
    database
      .getRealtimeDataAllValue(deviceId, dataTypeId)
      .then(allRealtimeValueData => {
        let lastKeys = {};
        if (allRealtimeValueData) {
          for (const [dataIdx, realtimeValueData] of Object.entries(
            allRealtimeValueData
          )) {
            lastKeys[dataIdx] = 0;
            if (realtimeValueData) {
              const keys = Object.keys(realtimeValueData);
              lastKeys[dataIdx] = keys[keys.length - 1];
              allData[dataIdx] = [];
              allData[dataIdx].push(
                ...keys.map(key => [
                  new Date(parseInt(key)).toISOString(),
                  realtimeValueData[key]
                ])
              );
            }
          }
        } else {
          dataDetails.forEach(dataDetail => {
            lastKeys[dataDetail['data_idx']] = 0;
            allData[dataDetail['data_idx']] = [];
          });
        }
        setAllData(allData);
        setDataPointCount(countData(allData));
        setLoading(false);
        unsubscribes = dataDetails.map(dataDetail =>
          database.onRealtimeDataChildAdded(
            deviceId,
            dataDetail['data_type_id'],
            dataDetail['data_idx'],
            lastKeys[dataDetail['data_idx']],
            (time, value) => {
              allData[dataDetail['data_idx']].push([
                new Date(parseInt(time)).toISOString(),
                value
              ]);
              setAllData(allData);
              setDataPointCount(countData(allData));
            }
          )
        );
      });
    return () => {
      unsubscribes.forEach(unsubscribe => {
        unsubscribe();
      });
    };
  }, []);

  const filteredSubfixNames = dataDetails
    .filter(d => allData[d['data_idx']] && allData[d['data_idx']].length > 0)
    .map(d => (d?.tag ? d?.tag : ''));
  const filteredDataList = dataDetails
    .filter(d => allData[d['data_idx']] && allData[d['data_idx']].length > 0)
    .map(d => allData[d['data_idx']]);
  const filteredColorTones =
    filteredDataList.length === 1
      ? [getColor('primary')]
      : dataDetails
          .map((d, i) => [d, i])
          .filter(
            ([d]) => allData[d['data_idx']] && allData[d['data_idx']].length > 0
          )
          .map(([, i]) => colorTones[i]);
  let fullName = prefixName;
  if (filteredSubfixNames.filter(x => x && x !== '').length > 0)
    fullName += ` (${filteredSubfixNames
      .filter(x => x && x !== '')
      .join(', ')})`;

  return (
    <Row className="g-3 mb-3">
      <Col>
        <FalconCardHeader title={fullName} titleTag="h6" className="px-0" />

        {isLoading || dataPointCount > 0 ? (
          <ReactEChartsCore
            loadingOption={{
              text: t('loading'),
              color: getColor('primary')
            }}
            showLoading={isLoading}
            echarts={echarts}
            option={getOptions(
              filteredSubfixNames,
              filteredDataList,
              filteredColorTones
            )}
            style={{ height: '15rem' }}
          />
        ) : (
          <p className="text-center my-5">{t('noData')}</p>
        )}
      </Col>
    </Row>
  );
};

RealtimeChart.propTypes = {
  deviceId: PropTypes.string.isRequired,
  dataTypeId: PropTypes.number.isRequired,
  dataDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  telemetryType: PropTypes.object.isRequired
};

export default RealtimeChart;
