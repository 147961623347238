import useAuth from '../hooks/useAuth';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
// pages
// import Login from '../pages/auth/Login';
// components
// import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation('/authentication/login');
      }
    }
  }, [requestedLocation]);

  if (!isInitialized) {
    return <></>;
  }

  if (!isAuthenticated) {
    return <Navigate to={'/authentication/login'} />;
  }

  return <>{children}</>;
}
