import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeviceGroupListItem = ({
  className,
  id = '',
  name = 'VIP Organic Farm',
  description = 'VIP Organic farm in Nakhon Nayok province',
  devices = []
}) => (
  <Card className={`overflow-hidden ${className}`}>
    {/* <Background image={corner1} className="p-card bg-card" /> */}
    <Card.Body className="position-relative">
      <h5 className="text-primary"> {name} </h5>
      <p className="fs--1 mb-0">{description}</p>
      <Button
        as={Link}
        variant="link"
        className="fs--1 text-primary mt-lg-3 ps-0"
        to={`/group/${id}`}
        state={{
          group: {
            name: name,
            description: description,
            id: id,
            devices: devices
          }
        }}
      >
        View
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button>
    </Card.Body>
  </Card>
);

DeviceGroupListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.string)
};

export default DeviceGroupListItem;
