import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Nav, Button, Form } from 'react-bootstrap';
import corner from 'assets/img/illustrations/corner-4.png';
import Background from 'components/common/Background';
import DeviceListItem from './DeviceListItem';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const DeviceGroup = () => {
  const { id } = useParams();

  const { state } = useLocation();

  const { firestore } = useAuth();

  const [deviceGroup, setDeviceGroup] = useState({
    name: state?.group?.name || '',
    description: state?.group?.description || '',
    id: state?.group?.id || id,
    devices: []
  });

  const [automations, setAutomations] = useState([]);

  const { t } = useTranslation();

  const groupPath = location.pathname.match('public-group')
    ? 'public-group'
    : 'group';

  const handleAddAutomation = () => {
    const tempName =
      'New Automation #' +
      parseInt(
        automations.filter(a => a.name.startsWith('New Automation')).length + 1
      );

    const automation = {
      name: tempName,
      description: tempName,
      triggers: [],
      actions: [],
      triggers_type: 'all'
    };

    firestore.addAutomation(deviceGroup.id, automation).then(docRef => {
      automation.id = docRef.id;
      automation.isEditing = false; //for edit name
      setAutomations([...automations, automation]);
    });
  };

  const handleAutomationNameChange = (e, automationId) => {
    const _automations = [...automations];
    const index = _automations.findIndex(a => a.id === automationId);
    _automations[index].newName = e.target.value;
    setAutomations(_automations);
  };

  const handleSetAutomationName = (e, automationId) => {
    const _automations = [...automations];
    const index = _automations.findIndex(a => a.id === automationId);
    if (_automations[index].newName) {
      const newName = _automations[index].newName;
      if (newName !== _automations[index].name) {
        _automations[index].name = newName;
        firestore.setAutomationName(deviceGroup.id, automationId, newName);
      }
      _automations[index].isEditing = false;
      setAutomations(_automations);
    } else {
      _automations[index].isEditing = false;
      setAutomations(_automations);
    }
  };

  const handleDeleteAutomation = (e, automationId) => {
    firestore.deleteAutomation(deviceGroup.id, automationId).then(() => {
      setAutomations(automations.filter(a => a.id !== automationId));
    });
  };

  useEffect(() => {
    if (firestore) {
      Promise.all([
        firestore.getPlace(deviceGroup.id),
        firestore.getDevicesOfPlace(deviceGroup.id),
        firestore.getAutomations(deviceGroup.id)
      ]).then(([place, devices, automations]) => {
        const _deviceGroup = { ...deviceGroup };
        _deviceGroup.name = place.name;
        _deviceGroup.description = place.description;
        _deviceGroup.devices = devices;
        setDeviceGroup(_deviceGroup);
        setAutomations([...automations], { isEditing: false });
      });
    }
  }, []);

  const subpageComponents = {
    allDevices: (
      <>
        <Row className="g-3 mb-3">
          {deviceGroup &&
            //filter ct-view due to not supported feature
            deviceGroup.devices
              .filter(d => !d.id.startsWith('ct-view'))
              .map((device, index) => {
                return (
                  <Col lg={6} key={index}>
                    <DeviceListItem
                      name={deviceGroup.name}
                      description={deviceGroup.description}
                      id={deviceGroup.id}
                      device={device}
                      className="h-100"
                    />
                  </Col>
                );
              })}
        </Row>
      </>
    ),
    automation: (
      <>
        <Row className="g-3 mb-3">
          {automations &&
            automations.map(automation => {
              const { id, name, actions, triggers } = automation;
              return (
                <Col key={id} lg={6}>
                  <Card>
                    <Card.Body className="position-relative">
                      <Flex direction="row" alignItems="center">
                        {automation.isEditing ? (
                          <Form.Control
                            className=" fs--1 px-0 w-auto "
                            size="sm"
                            type="text"
                            onChange={e => handleAutomationNameChange(e, id)}
                            defaultValue={name === undefined ? '' : name}
                            onBlur={e => handleAutomationNameChange(e, id)}
                          />
                        ) : (
                          <Card.Text className="text-primary m-0">
                            {name}
                          </Card.Text>
                        )}
                        {!automation.isEditing ? (
                          <FontAwesomeIcon
                            onClick={() => {
                              const _automations = [...automations];
                              const index = _automations.findIndex(
                                a => a.id === id
                              );
                              _automations[index].isEditing = true;
                              setAutomations(_automations);
                            }}
                            icon="edit"
                            className="px-2 cursor-pointer"
                            color="text-secondary"
                          />
                        ) : (
                          <FontAwesomeIcon
                            onClick={e => handleSetAutomationName(e, id)}
                            icon={faSave}
                            className="px-2 cursor-pointer"
                            color="text-secondary"
                          />
                        )}
                      </Flex>

                      <Flex direction="column py-2">
                        <p className="fs--2 m-0 text-secondary ">
                          {t('actions')}: {actions ? actions.length : 0}
                        </p>
                        <p className="fs--2 m-0 text-secondary">
                          {t('triggers')}: {triggers ? triggers.length : 0}
                        </p>
                      </Flex>
                    </Card.Body>
                    <Card.Footer>
                      <Flex
                        direction="row"
                        alignItems="center"
                        justifyContent="between"
                      >
                        <FontAwesomeIcon
                          onClick={e => handleDeleteAutomation(e, id)}
                          icon="trash"
                          className="cursor-pointer"
                          color="text-secondary"
                        />
                        <Button
                          as={Link}
                          variant="link"
                          className="fs--1 text-primary px-0"
                          to={`/${groupPath}/${deviceGroup.id}/automation/${id}`}
                          state={{
                            group: {
                              name: deviceGroup.name,
                              description: deviceGroup.description,
                              id: deviceGroup.id
                            }
                          }}
                        >
                          {t('view')}
                          <FontAwesomeIcon
                            icon="chevron-right"
                            className="ms-1 fs--2"
                          />
                        </Button>{' '}
                      </Flex>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
        </Row>
        <Row className="g-3 mb-3">
          <Col lg={12} className="text-end">
            <IconButton
              className="w-100 w-lg-auto"
              variant="outline-primary"
              size="sm"
              icon="plus"
              iconAlign="left"
              onClick={handleAddAutomation}
            >
              {t('add')}
            </IconButton>
          </Col>
        </Row>
      </>
    )
  };
  const subpageList = Object.keys(subpageComponents);
  const [subpage, setSubpage] = useState(subpageList[0]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={12} className="gx-0">
          <Card className={`overflow-hidden`}>
            <Background image={corner} className="p-card bg-card" />
            <Card.Body className="position-relative">
              <h2 className="">{deviceGroup.name}</h2>
              <p className="fs--1 mb-0">{deviceGroup.description}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12} className="gx-0">
          <Nav
            variant="pills"
            defaultActiveKey={subpage}
            onSelect={key => setSubpage(key)}
          >
            {subpageList.map(btnName => (
              <>
                {groupPath === 'public-group' && btnName === 'automation' ? (
                  <></>
                ) : (
                  <Nav.Item key={btnName}>
                    <Nav.Link eventKey={btnName}>{t(btnName)}</Nav.Link>
                  </Nav.Item>
                )}
              </>
            ))}
          </Nav>
        </Col>
        <Col lg={12} className="gx-0">
          {subpageComponents[subpage]}
        </Col>
      </Row>
    </>
  );
};

export default DeviceGroup;
